/** @format */

import "./App.scss";
import "swiper/css";
import "swiper/css/pagination";
import Home from "./pages/Home";
import "swiper/css/effect-fade";
import "swiper/css/bundle";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Base from "./components/layouts/Base";
import Faqs from "./components/utils/Faqs";
import Page6 from "./components/elements/Page6";
import ReactGA from "react-ga";
import Page2 from "./components/elements/Page2";
import Roadmap from "./components/elements/Roadmap";
ReactGA.initialize("UA-250708013-1");
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  return (
    <BrowserRouter>
      <Base>
        <Routes>
          <Route index path='/' element={<Home />} />
          <Route path='/tokenomics' element={<Page6 />} />
          <Route path='/faqs' element={<Faqs />} />
          <Route path='/roadmap' element={<Roadmap />} />
        </Routes>
      </Base>
    </BrowserRouter>
  );
}

export default App;
