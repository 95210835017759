/** @format */

import React from "react";
import {
  FaTwitter,
  FaDiscord,
  FaTelegram,
  FaInstagram,
  FaReddit,
  FaFacebook,
  FaGithub,
} from "react-icons/fa";
import { SiMedium } from "react-icons/si";
import CountDown from "../utils/CountDown";

function Hero() {
  return (
    <div className='container-fluid hero-bg' id='about'>
      <div className='container-fluid   '>
        {/* <CountDown /> */}
        <div className='row'>
          <div className='col-md-4'>
            <p>
              Envision a future where crafting a game is as simple as creating a
              TikTok video.
            </p>
          </div>
          <div className='col-md-4'></div>
          <div className='col-md-4'>
            <p>
              Turn any video into a fully interactive game with just a few
              clicks through AI driven Game Models.
            </p>
          </div>
        </div>
        <h1>
          Povo.ai is disrupting the gaming industry 
        </h1>

        {/* <div className="row fixed-bottom pb-3 footer-sc m-auto text-center">
          <div className="col-md-2 m-auto">
            <a href="#about">
              <button className="footer-btn active-btn ">Vision</button>
            </a>
            <a href="#Aim">
              <button className="footer-btn">Aim</button>
            </a>
          </div>
          <div className="col-md-10  page7 m-auto">
            <a
              href="https://medium.com/@PovoAIfin"
              target="_blank"
              rel="noreferrer"
            >
              <button style={{ color: "#dadee3" }}>
                <SiMedium /> Medium
              </button>
            </a>
            <a
              href="https://twitter.com/PovoAIfin"
              target="_blank"
              rel="noreferrer"
            >
              <button style={{ color: "#0ad4fc" }}>
                <FaTwitter /> Twitter
              </button>
            </a>
            <a
              href="https://t.me/PovoAI_Portal"
              target="_blank"
              rel="noreferrer"
            >
              <button style={{ color: "#0ad4fc" }}>
                <FaTelegram /> Telegram
              </button>
            </a>
            <a
              href="https://discord.gg/D9ha3AU9Ev"
              target="_blank"
              rel="noreferrer"
            >
              <button style={{ color: "#5761FC" }}>
                <FaDiscord /> Discord
              </button>
            </a>
            <a
              href="https://www.instagram.com/PovoAIfin/"
              target="_blank"
              rel="noreferrer"
            >
              <button style={{ color: "#FD096D" }}>
                <FaInstagram /> Instagram
              </button>
            </a>

            <a
              href="https://github.com/PovoAIfin"
              target="_blank"
              rel="noreferrer"
            >
              <button style={{ color: "#dadee3" }}>
                <FaGithub /> Github
              </button>
            </a>

            <a
              href="https://www.reddit.com/r/PovoAIfin/"
              target="_blank"
              rel="noreferrer"
            >
              <button style={{ color: "#F24100" }}>
                <FaReddit /> Reddit
              </button>
            </a>

            <a
              href="https://www.facebook.com/PovoAIFin/"
              target="_blank"
              rel="noreferrer"
            >
              <button style={{ color: "#1977F3" }}>
                <FaFacebook /> Facebook
              </button>
            </a>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Hero;
