/** @format */

import React from "react";
import { BsArrowRightCircle } from "react-icons/bs";
import { Link as Links } from "react-router-dom";

function Header() {
  return (
    <nav className='navbar navbar-expand-lg  fixed-top'>
      <Links className='navbar-brand mx-3' to='/'>
        <img src='logo.png' alt='' className='mx-3' width={70} />
        Povo
      </Links>
      <button
        className='navbar-toggler'
        type='button'
        data-bs-toggle='collapse'
        data-bs-target='#navbarSupportedContent'
        aria-controls='navbarSupportedContent'
        aria-expanded='false'
        aria-label='Toggle navigation'>
        <span className='navbar-toggler-icon' />
      </button>
      <div className='collapse navbar-collapse' id='navbarSupportedContent'>
        <ul className='navbar-nav m-auto mb-2 mb-lg-0'>
          <li className='nav-item '>
            <a className='nav-link ' href='/'>
              Home
            </a>
          </li>
          {/* <li className='nav-item'>
            <a
              className='nav-link'
              target='_blank'
              rel='noreferrer'
              href='>
              Whitepaper
            </a>
          </li> */}

          <li className='nav-item'>
            <Links className='nav-link' to='/tokenomics'>
              Tokenomics
            </Links>
          </li>
          <li className='nav-item'>
            <Links className='nav-link' to='/roadmap'>
              Roadmap
            </Links>
          </li>
          {/* <li className='nav-item'>
            <Links className='nav-link' to='/faqs'>
              Faqs
            </Links>
          </li> */}
        </ul>
        {/* <div className='button-border'>
          <button className='nav-btn'>
            Join Whitelist <BsLockFill />
          </button>
        </div> */}
        <a className='button mx-3' href='#abc' target='_blank'>
          <span className='border-element' />
          <span className='button-span'>
            <span className='border-element' />
            Play Game
            {/* <span className='button-animated-element'>
              <span className='button-animated-element-text'>LIVE</span>
            </span> */}
            <span className='border-element' />
          </span>
          <span className='border-element' />
        </a>
      </div>
    </nav>
  );
}

export default Header;
