/** @format */

import React from "react";

function Page2() {
  return (
    <div className='container-fluid page1 px-5 page2'  id='products'>
      <div className='row'>
        <div className='col-md-12 text-center'>
          <h2>Solution</h2>
          {/* <p>
            Bringing the future closer, one step at a time. Today, we're <br />{" "}
            more excited than ever to be helping build the Web3 ecosystem <br />{" "}
            and prepare it for mass adoption. Join us on our path and help make
            history.
          </p> */}
        </div>
      </div>

      <div className='row mt-2'>
        <div className='col-md-4'></div>
        <div className='col-md-4'>
          <div className='box'>
            <h4>Code2Earn</h4>
            <p>
              Outsource your coding services to third-party clients and other
              service consumers to build your clientele.
            </p>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='box'>
            <h4>Quest2Earn</h4>
            <p>
              Quest2Earn is a question-and-answer platform where users are
              compensated in $PovoAI token for producing insightful responses.
            </p>
          </div>
        </div>
        <div className='col-md-4'></div>

        <div className='col-md-4 mt-3'>
          <div className='box'>
            <h4>Certifications</h4>
            <p>
              Learn at your own pace in our certificate module about any of the
              Web 3.0 tech topics of your choice, and earn downloadable NFT
              certificates.
            </p>
          </div>
        </div>

        <div className='col-md-4 mt-3'>
          <div className='box'>
            <h4>Referrals</h4>
            <p>
              <strong>Grow together!</strong> Refer PovoAI to your followers on
              major social platforms like Facebook, Whatsapp and Twitter and
              build your own teams to collectively benefit from each other's
              learning.
            </p>
          </div>
        </div>
      </div>
      <h1 className='text-start'>About</h1>
      {/* <div className='row fixed-bottom pb-5 footer-sc'>
        <div className='col-md-1'></div>
        <div className='col-md-6'>
          <a href='#products'>
            <button className='footer-btn active-btn'>Gamified Learning</button>
          </a>
          <a href='#features'>
            <button className='footer-btn '>Quiz Cash</button>
          </a>
          <button className='footer-btn '>Web3 Onboarding Combo</button>
        </div>
        <div className='col-md-4'></div>
      </div> */}
    </div>
  );
}

export default Page2;
