/** @format */

import React from "react";

function Page5() {
  return (
    <div className='container-fluid page1 px-5' id='ecosystem'>
      <div className='row'>
        <div className='col-md-12 '>
          <h1 className='text-center'>PovoAI ecosystem</h1>
          <p className='mt-2 mb-5'></p>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-4'>
          <div className='box'>
            <h4>Turn any NFTs, Video or Image into a Game</h4>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='box'>
            <h4>Monetize content through interactive playable ads.</h4>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='box'>
            <h4>Interactive Ads 4x boost conversion</h4>
          </div>
        </div>

        <div className='col-md-4'>
          <div className='box'>
            <h4>In Game Purchase Assets</h4>
          </div>
        </div>

        <div className='col-md-4'>
          <div className='box'>
            <h4>Licensing content</h4>
          </div>
        </div>

        <div className='col-md-4'>
          <div className='box'>
            <h4>Generative AI Game Models</h4>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='box'>
            <h4>Mobile App (Android/Iphone)</h4>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='box'>
            <h4>Governance System</h4>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='box'>
            <h4>Marketplace</h4>
          </div>
        </div>
     
      </div>
    </div>
  );
}

export default Page5;
