/** @format */

import { Pie, G2 } from "@ant-design/plots";

const Chart = () => {
  const G = G2.getEngine("canvas");
  const data = [
    {
      type: "Initial Liquidity Allocation for DEX",
      value: 30,
    },
    {
      type: "Presale Tokens Allocation",
      value: 20,
    },
    {
      type: "Advisory Board",
      value: 5,
    },
    {
      type: "Marketing",
      value: 6,
    },
    {
      type: "Product Development & Team ",
      value: 7.5,
    },
    {
      type: "Research & Development",
      value: 3.5,
    },
    {
      type: "Gaming Reward",
      value: 10,
    },
    {
      type: "Airdrop & Bounty Campaign",
      value: 4,
    },
    {
      type: "3 Tier CEX liquidity (Lbank, Bitmart, Hotbit)",
      value: 6,
    },
    {
      type: "1st Tier CEX liquidity (Binance, Huobi, Kucoin)",
      value: 8,
    },
  ];
  const cfg = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.75,
    legend: false,
    label: {
      type: "spider",
      labelHeight: 40,
      formatter: (data, mappingData) => {
        const group = new G.Group({});
        group.addShape({
          type: "circle",
          attrs: {
            x: 0,
            y: 0,
            width: 40,
            height: 50,
            r: 5,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: "text",
          attrs: {
            x: 10,
            y: 8,
            text: `${data.type}`,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: "text",
          attrs: {
            x: 0,
            y: 25,
            text: `${data.value}个 ${data.percent * 100}%`,
            fill: "rgba(0, 0, 0, 0.65)",
            fontWeight: 700,
          },
        });
        return group;
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };
  const config = cfg;
  return <Pie {...config} />;
};

export default Chart;
