/** @format */

import React from "react";
import MultiCarousal from "../utils/MultiCarousal";
import { MdDoubleArrow } from "react-icons/md";

function Page3() {
  return (
    <div className='container-fluid page1  pt-5' id='features'>
      <div className='row pt-5'>
        <div className='col-md-12'></div>
      </div>

      <div className='row mt-2'>
        <div className='col-md-4 m-auto'>
          <h2>Game Play</h2>
          <p>SELF SERVICE PLATFORM</p>
          {/* <div className='box mt-5'>
            <h4>Perpetual ‘Earning’ Curve!</h4>
            <p>Web3 on-ramp education at a gamified</p>
          </div> */}
          {/* <div className='box mt-5'>
            <p>
              Equipped with variable learning mediums, PovoAI provides
              comprehensive guide to learning each and everything about Web3
              innovations{" "}
            </p>
          </div> */}
        </div>
        <div className='col-md-3 d-flex'>
          <div className='d-flex'>
            {/* <img src='assets/2b.svg' alt='' className='w-50 ' /> */}
            <img
              src='assets/mob.png'
              alt=''
              className='big-img'
              style={{ width: "400px" }}
            />
          </div>
        </div>
        <div className='col-md-4 '>
          <div className=''>
            <MultiCarousal />
          </div>
        </div>
      </div>
      <h1 className='text-start'>Feature</h1>

      <div className='row px-4 ' style={{ marginTop: "-60px" }}>
        <div className='   text-end mb-5 z-index'>
        <a >
            {" "}
            <div className='footer-txt '>
              Features <MdDoubleArrow />
            </div>
          </a>
          <a>
            <div className='footer-txt txt-active  mt-3'>
              Game play <MdDoubleArrow />
            </div>
          </a>
        </div>
      </div>

      {/* <div className='row fixed-bottom pb-5 footer-sc'>
        <div className='col-md-1'></div>
        <div className='col-md-6'>
          <a href='#products'>
            <button className='footer-btn '>Gamified Learning</button>
          </a>
          <a href='#features'>
            <button className='footer-btn active-btn'>Quiz Cash</button>
          </a>
          <button className='footer-btn '>Web3 Onboarding Combo</button>
        </div>
        <div className='col-md-4'></div>
      </div> */}
    </div>
  );
}

export default Page3;
