/** @format */

import React from "react";

function Roadmap() {
  return (
    <div className='container-fluid page1 px-5 mt-5 h-auto '>
      <div className='row mt-2'>
        <div className='col-md-3'>
          <div className='box'>
            <p>
              - Povo.ai Branding & Logo Designing <br />
              - Website Release 2.0 <br />
              - Whitepaper, Gitbook <br />
              - Mobile App Development Phase <br />
              - Tokenomics <br />
              - Growth hacking <br />
              - Governance platform <br />
              - Strategic Partnerships <br />
              - Private sale (vesting) <br />
              - IGO Launchpad <br />
              - 50k Mobile App Downloads <br />
              - Generative AI Models (Alpha) <br />
              - MVP Release 1.0 <br />
              - Povo Game Engine Architecture <br />
              - Creators Waitlisting <br />
              - DEX liquidity <br />
              - 3 tier CEX listing (Lbank, Hotbit, Bitmart) <br />
              - Multi-Chain bridge integration <br />
              - Bounty campaign <br />- 50k Mobile App Downloads
            </p>
            <h4>Q4 2023</h4>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='box'>
            <p>
              - Collaboration with Influencers, Content Creators
              <br /> - Povo Game Engine For Content Creators (Beta Release 1.0)
              <br /> - Partnerships with social media platforms
              <br /> - Brand awareness campaign
              <br /> - Contest competition
              <br /> - 420k Mobile App Downloads
              <br /> - Generative AI Models (Beta)
              <br /> - Onboard Ambassadors
              <br /> - Private investors (25% Vesting release)
              <br /> - Onboard Advisors
              <br /> - Integrate Playable Ads
              <br /> - Partnership with LMS
              <br /> - Bounty reward distribution
              <br /> - Integrate EVM compatible Chains
            </p>
            <h4>Q1. 2024</h4>
          </div>
        </div>

        <div className='col-md-3 mt-3'>
          <div className='box'>
            <p>
              - Reward system for community
              <br /> - Povo Game Engine main-net release
              <br /> - PS5 & Xbox compatibility
              <br /> - SDK for traditional games (beta)
              <br /> - Viral gaming contest
              <br /> - Private investors (25% Vesting release)
              <br /> - 1.5M+ Mobile App Downloads
              <br /> - Generative AI Models (1.0 release)
              <br /> - CEX listing (Binance, Huobi, Kucoin)
              <br /> - Hiring Industry leaders
              <br /> - Gaming Hackathon
              <br /> - Povo Merch
            </p>
            <h4>Q2. 2024 </h4>
          </div>
        </div>

        <div className='col-md-3 mt-3'>
          <div className='box'>
            <p>
              - Improve Game Engine
              <br /> - Advance Generative AI Game Models
              <br /> - Strategic Partnership
              <br /> - 5M+ Mobile App Downloads
              <br /> - Organise gaming events
              <br /> - Private investors (25% Vesting release)
              <br /> - Participate in Pocket Games Events
              <br /> - Join Gaming Conferences
              <br /> - Bug free SDK release
              <br /> - Collaboration with celebrities
              <br /> - API documentation for developers
            </p>
            <h4>Q3. 2024</h4>
          </div>
        </div>
        <div className='col-md-4 mt-3'>
          <div className='box'>
            <p>
              - Partnership with Gaming Engine (Unity3D, Unreal)
              <br /> - Marketplace for content creators
              <br /> - Convert blockchain currency to fiat currency
              <br /> - Leaderboard for winners
              <br /> - 25M+ Mobile App Downloads
              <br /> - Live reaction based games
              <br /> - On demand content dashboard
              <br /> - Private investors (25% Vesting release)
              <br /> - Release bug free web based game engine
              <br /> - Povo acquisition for early Investors Multi Million exit
              <br /> - Staking Platform (Passive Income for Povo token holders)
            </p>
            <h4>Q4. 2024</h4>
          </div>
        </div>
      </div>
      <h1 className='text-start position-absolute bottom-0 end-0  '>Roadmap</h1>
    </div>
  );
}

export default Roadmap;
