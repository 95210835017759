/** @format */

import React from "react";
import {
  FaTwitter,
  FaDiscord,
  FaTelegram,
  FaInstagram,
  FaReddit,
  FaFacebook,
  FaGithub,
} from "react-icons/fa";
import { SiMedium } from "react-icons/si";

function Page7() {
  return (
    <div className='container-fluid page1 px-5  mt-5' id='backers'>
      <div className='row mt-5'>
        <div className='col-md-2'>
          <div className='box'>
            <img src='assets/Onlyfans.png' alt='' className=' w-100' />
          </div>
        </div>
        <div className='col-md-1'>
          <div className='box'>
            <img src='assets/insta.png' alt='' className=' w-100' />
          </div>
        </div>
        <div className='col-md-1'>
          <div className='box'>
            <img src='assets/snap.png' alt='' className=' w-100' />
          </div>
        </div>
        <div className='col-md-1'>
          <div className='box'>
            <img src='assets/png-transparent-tiktok-2-hd-logo.png' alt='' className=' w-100' />
          </div>
        </div>
        <div className='col-md-1'>
          <div className='box'>
            <img src='assets/Youtube_shorts_icon.png' alt='' className=' w-100' />
          </div>
        </div>

        <div className='col-md-2'>
          <div className='box'>
            <img src='assets/Logonetflix.png' alt='' className=' w-100' />
          </div>
        </div>
        <div className='col-md-2'>
          <div className='box'>
            <img src='assets/unity.png' alt='' className=' w-100' />
          </div>
        </div>
        <div className='col-md-1'>
          <div className='box'>
            <img src='assets/ue-logo.png' alt='' className=' w-100' />
          </div>
        </div>
        <div className='col-md-2'>
          <div className='box'>
            <img src='assets/blender_logo_socket.png' alt='' className=' w-100' />
          </div>
        </div>
        <div className='col-md-1'>
          <div className='box'>
            <img src='assets/adobe-photoshop-logo-0.png' alt='' className=' w-100' />
          </div>
        </div>
        <div className='col-md-2'>
          <div className='box'>
            <img src='assets/canva.png' alt='' className=' w-75' />
          </div>
        </div>

        <div className='col-md-1'>
          <div className='box'>
            <img src='assets/toyota-logos-brands-logotypes-0.png' alt='' className=' w-100' />
          </div>
        </div>

        <div className='col-md-1'>
          <div className='box'>
            <img src='assets/nba-logo-transparent.png' alt='' className=' w-50' />
          </div>
        </div>

        <div className='col-md-2'>
          <div className='box'>
            <img src='assets/5887c5a1bc2fc2ef3a186050.png' alt='' className=' w-100' />
          </div>
        </div>

        <div className='col-md-2'>
          <div className='box'>
            <img src='assets/Samsung_Logo.svg.png' alt='' className=' w-100' />
          </div>
        </div>

        <div className='col-md-1'>
          <div className='box'>
            <img src='assets/MetaMask_Fox.svg.png' alt='' className=' w-100' />
          </div>
        </div>

        <div className='col-md-1'>
          <div className='box'>
            <img src='assets/1026px-Uniswap_Logo.svg.png' alt='' className=' w-100' />
          </div>
        </div>
       
        <div className='col-md-2'>
          <div className='box'>
            <img src='assets/binance.svg' alt='' className=' w-100' />
          </div>
        </div>

       
      
      </div>

      <div className='row mt-5'>
      <div className='col-md-3 mt-4 page7-anchor'>
          <p style={{ color: "#545D69", fontSize: "16px" }} className='w-50'>
            Copyright © 2022 Povo.ai
          </p>
          <a href='/'>Terms of Token Service</a>
          <a href='/' className='mx-4'>
            Privacy Policy
          </a>
        </div>
        <div className='col-md-9  page7 m-auto'>
          {/* <a href='#abc' target='_blank' rel='noreferrer'>
            <button style={{ color: "#dadee3" }}>
              <SiMedium /> Medium
            </button>
          </a> */}
          <a href='#abc' target='_blank' rel='noreferrer'>
            <button style={{ color: "#0ad4fc" }}>
              <FaTwitter /> Twitter
            </button>
          </a>
          <a href='#abc' target='_blank' rel='noreferrer'>
            <button style={{ color: "#0ad4fc" }}>
              <FaTelegram /> Telegram
            </button>
          </a>
          <a href='#abc' target='_blank' rel='noreferrer'>
            <button style={{ color: "#5761FC" }}>
              <FaDiscord /> Discord
            </button>
          </a>
          <a href='#abc' target='_blank' rel='noreferrer'>
            <button style={{ color: "#FD096D" }}>
              <FaInstagram /> Instagram
            </button>
          </a>

          {/* <a href='#abc' target='_blank' rel='noreferrer'>
            <button style={{ color: "#dadee3" }}>
              <FaGithub /> Github
            </button>
          </a> */}

          <a href='#abc' target='_blank' rel='noreferrer'>
            <button style={{ color: "#F24100" }}>
              <FaReddit /> Reddit
            </button>
          </a>

          <a href='#abc' target='_blank' rel='noreferrer'>
            <button style={{ color: "#1977F3" }}>
              <FaFacebook /> Facebook
            </button>
          </a>
        </div>

     
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <h1>Our Backers</h1>
        </div>
      </div>
    </div>
  );
}

export default Page7;
