/** @format */

import React from "react";
import Chart from "../utils/Chart";

function Page6() {
  return (
    <div className='container-fluid page1 px-5' id='tokenomics'>
      <div className='row mt-5'>
        <div className='col-md-4 mt-4'>
          <div className='box'>
            <h4>Raised Funds Allocation</h4>
            <p className='page6'>
              Liquidity <span style={{ color: "#0063f7" }}>60%</span>
            </p>
            <div className='progress'>
              <div
                className='progress-bar progress-bar-striped progress-bar-animated'
                role='progressbar'
                style={{ width: "60%" }}
                aria-valuenow='60'
                aria-valuemin='0'
                aria-valuemax='100'></div>
            </div>
            <p className='page6'>
              Resource Hiring <span style={{ color: "#0063f7" }}>7%</span>
            </p>
            <div className='progress'>
              <div
                className='progress-bar bar-2  progress-bar-striped progress-bar-animated'
                role='progressbar'
                style={{ width: "7%" }}
                aria-valuenow='7'
                aria-valuemin='0'
                aria-valuemax='100'></div>
            </div>
            <p className='page6'>
              Game Engine for creators{" "}
              <span style={{ color: "#0063f7" }}>10%</span>
            </p>
            <div className='progress'>
              <div
                className='progress-bar bar-3  progress-bar-striped progress-bar-animated'
                role='progressbar'
                style={{ width: "10%" }}
                aria-valuenow='10'
                aria-valuemin='0'
                aria-valuemax='100'></div>
            </div>
            <p className='page6'>
              Team funds for development{" "}
              <span style={{ color: "#0063f7" }}>10%</span>
            </p>
            <div className='progress'>
              <div
                className='progress-bar bar-4  progress-bar-striped progress-bar-animated'
                role='progressbar'
                style={{ width: "10%" }}
                aria-valuenow='25'
                aria-valuemin='0'
                aria-valuemax='100'></div>
            </div>
            <p className='page6'>
              R&D <span style={{ color: "#0063f7" }}>10%</span>
            </p>
            <div className='progress'>
              <div
                className='progress-bar bar-5  progress-bar-striped progress-bar-animated'
                role='progressbar'
                style={{ width: "10%" }}
                aria-valuenow='10'
                aria-valuemin='0'
                aria-valuemax='100'></div>
            </div>

            <p className='page6'>
              Strategic partnerships{" "}
              <span style={{ color: "#0063f7" }}>5%</span>
            </p>
            <div className='progress'>
              <div
                className='progress-bar bar-6  progress-bar-striped progress-bar-animated'
                role='progressbar'
                style={{ width: "5%" }}
                aria-valuenow='5'
                aria-valuemin='0'
                aria-valuemax='100'></div>
            </div>

            <p className='page6'>
              Game Reward <span style={{ color: "#0063f7" }}>8%</span>
            </p>
            <div className='progress'>
              <div
                className='progress-bar bar-7  progress-bar-striped progress-bar-animated'
                role='progressbar'
                style={{ width: "8%" }}
                aria-valuenow='8'
                aria-valuemin='0'
                aria-valuemax='100'></div>
            </div>
          </div>
        </div>
        <div className='col-md-1'></div>
        <div className='col-md-5 m-auto '>
          <div className='box'>
            <h4>$Povo Tokenomics</h4>
            <Chart />
          </div>
          {/* <div className='box mt-2'>
            <a href='#!' target='_blank' rel='noopener noreferrer'>
              <p>
                <img
                  src='assets/logo-bscscan-white.svg'
                  style={{ width: 100, display: "inline-block" }}
                  alt=''
                />{" "}
                0x0000000000000000000000000000000000000{" "}
              </p>
            </a>
          </div> */}
        </div>
      </div>
      <h1>Tokenomics</h1>
    </div>
  );
}

export default Page6;
