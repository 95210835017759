/** @format */

import React from "react";
import { MdDoubleArrow } from "react-icons/md";

function Page4() {
  return (
    <div className='container-fluid page1 page3 px-5' id='community'>
      <div className='row mt-5'>
        <div className='col-md-12'>
          <a
            href='#a'
            target='_blank'
            rel='noopener noreferrer'>
            <img src='assets/google.svg' width={200} alt='' />
          </a>
          <p className='mt-5 mb-5'>
            Web3 application dominating Google Play ranking ever
          </p>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-3'>
          <div className='box'>
            <p>Monthly Active Users</p>
            <h4>1,3 Millions</h4>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='box'>
            <p>Daily New Users</p>
            <h4>15,000+</h4>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='box'>
            <p>New User create game</p>
            <h4>68 %</h4>
          </div>
        </div>
      </div>

      <div className='row px-4 mt-4'>
        <div className='col-md-12 text-end mb-5'>
          <a >
            {" "}
            <div className='footer-txt '>
              Features <MdDoubleArrow />
            </div>
          </a>
          <a>
            <div className='footer-txt txt-active  mt-3'>
              Game play <MdDoubleArrow />
            </div>
          </a>
        </div>
      </div>
      <h1 className="">1st & only</h1>

      {/* <div className='row fixed-bottom pb-5 footer-sc'>
        <div className='col-md-1'></div>
        <div className='col-md-6'>
          <a href='#products'>
            <button className='footer-btn '>Gamified Learning</button>
          </a>
          <a href='#features'>
            <button className='footer-btn active-btn'>Quiz Cash</button>
          </a>
          <button className='footer-btn '>Web3 Onboarding Combo</button>
        </div>
        <div className='col-md-4'></div>
      </div> */}
    </div>
  );
}

export default Page4;
