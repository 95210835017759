/** @format */

import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaLessThan, FaGreaterThan } from "react-icons/fa";

const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 768 },
    items: 1,
    partialVisibilityGutter: 0,
  },
  tablet: {
    breakpoint: { max: 768, min: 600 },
    items: 1,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
  },
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className='carousel-button-group mx-5'>
      <button
        disabled={currentSlide === 0 ? true : false}
        className={`carousel-btn btn mx-5`}
        onClick={() => previous()}>
        <FaLessThan />
      </button>
      <button className='carousel-btn btn' onClick={() => next()}>
        {" "}
        <FaGreaterThan />{" "}
      </button>
    </div>
  );
};

export default function MultiCarousal() {
  return (
    <Carousel
      responsive={responsive}
      arrows={false}
      additionalTransfrom={0}
      itemClass={"react-carousel-item"}
      minimumTouchDrag={80}
      className='bg-sh'
      partialVisible
      renderButtonGroupOutside
      customButtonGroup={<ButtonGroup />}>
      <div className='carousal-1 '>
        <div className='card-body pt-3'>
          <h3 className='m-auto text-center'>
            1 <span className=' text-primary '>|</span> Record Video
          </h3>
          <p className='mx-2 text-justify p-2'>
            Unleash the boundless power of your imagination. <br />
            Capture video content on your phone and transform it into an
            interactive gaming experience. Whether it's NFTs, sports, dance, or
            the playful antics of your pets, the possibilities are endless.
          </p>
        </div>
      </div>

      <div className='carousal-1 '>
        <div className='card-body pt-3'>
          <h3 className='m-auto text-center'>
            2 <span className=' text-primary '>|</span> Upload Video
          </h3>
          <p className='mx-2 text-justify p-2'>
            select your pre-recorded videos from mobile gallery or connect your
            social media to choose content from profile either users can also
            select NFTs by connecting their metamask wallet.
          </p>
        </div>
      </div>

      <div className='carousal-1 '>
        <div className='card-body pt-3'>
          <h3 className='m-auto text-center'>
            3 <span className=' text-primary '>|</span> Chose Generative AI Game
            Models
          </h3>
          <p className=' mx-2 text-justify p-2'>
            Once you've chosen your video, NFTs, or image files, you can easily
            process them using our specialized Generative AI game models to
            transform your content into an interactive game. Our AI models
            provide valuable insights, including suggestions on implementing
            community reward mechanisms. For instance, you can effortlessly turn
            a basic running video into a dynamic Temple Run-style game. Our AI
            models consistently generate unique data, similar to ChatGPT,
            ensuring a fresh and engaging experience every time.
          </p>
        </div>
      </div>

      <div className='carousal-1 '>
        <div className='card-body pt-3'>
          <h3 className='m-auto text-center'>
            4 <span className=' text-primary '>|</span> Final Results
          </h3>
          <p className='mx-2 text-justify p-2'>
            We harness the power of high-performance GPUs on our servers to
            guarantee swift and efficient computational processing. Your game
            will be prepared in just 2 to 5 minutes. If the initial results
            don't meet your expectations, you have the freedom to generate a
            fresh game using a different AI model. The potential is boundless,
            and the process is as easy as adjusting Instagram filters.
          </p>
        </div>
      </div>

      <div className='carousal-1 '>
        <div className='card-body pt-3'>
          <h3 className='m-auto text-center'>
            5 <span className=' text-primary '>|</span> Publish and Earn Passive
            Income
          </h3>
          <p className='mx-2 text-justify p-2'>
            Once everything is ready, you can publish your game on the Povo.ai
            platform, where our vibrant community can enjoy it, earn rewards in
            Povo tokens, and provide valuable feedback and suggestions.
            Meanwhile, game creators have the opportunity to generate revenue
            through in-game playable ads.
          </p>
        </div>
      </div>
    </Carousel>
  );
}
