/** @format */

import React from "react";
import { MdDoubleArrow } from "react-icons/md";

function Teams() {
  return (
    <div className='container-fluid page1 page3 px-5'>
      <div>
        <div className='row flex-center sm-no-flex mt-5'>
          <div className='pull-right sm-no-float col-md-8'>
            <ul className='team-members'>
              {/* single member row starts */}
              <li className='clearfix'>
                <div className='member-details'>
                  <div>
                    <img
                      src='https://i.postimg.cc/zG5c7T02/168-1685371-corporate-headshot-pic-png-transparent-png.png'
                      alt='UI Designer'
                    />
                    <div className='member-info'>
                      <h3>Jennifer Tilly</h3>
                      <p>UI Designer</p>
                    </div>
                  </div>
                </div>
                <div className='member-details'>
                  <div>
                    <img
                      src='http://gocheckers.com/images/roster/josh_wesley_headshot_1718.png'
                      alt='UI Designer'
                    />
                    <div className='member-info'>
                      <h3>John Doe</h3>
                      <p>UI Designer</p>
                    </div>
                  </div>
                </div>
                <div className='member-details'>
                  <div>
                    <img src='https://i.postimg.cc/FKqR0FNp/Andrew-Stader-Headshot-Low-Res-Transparent-Background.png' />
                    <div className='member-info'>
                      <h3>David Warner</h3>
                      <p>UI Designer</p>
                    </div>
                  </div>
                </div>
              </li>
              {/* /single member row ends */}
              {/* single member row starts */}
              <li className='clearfix'>
                <div className='member-details'>
                  <div>
                    <img
                      src='https://i.postimg.cc/FKqR0FNp/Andrew-Stader-Headshot-Low-Res-Transparent-Background.png'
                      alt='UI Designer'
                    />
                    <div className='member-info'>
                      <h3>John Doe</h3>
                      <p>UI Designer</p>
                    </div>
                  </div>
                </div>
                <div className='member-details'>
                  <div>
                    <img
                      src='http://gocheckers.com/images/roster/andrew_poturalski_headshot_1718.png'
                      alt='UI Designer'
                    />
                    <div className='member-info'>
                      <h3>John Doe</h3>
                      <p>UI Designer</p>
                    </div>
                  </div>
                </div>
                <div className='member-details'>
                  <div>
                    <img
                      src='http://gocheckers.com/images/roster/andrew_poturalski_headshot_1718.png'
                      alt='UI Designer'
                    />
                    <div className='member-info'>
                      <h3>John Doe</h3>
                      <p>UI Designer</p>
                    </div>
                  </div>
                </div>
              </li>
              {/* /single member row ends */}
              {/* single member row starts */}
              <li className='clearfix'>
                <div className='member-details'>
                  <div>
                    <img
                      src='http://gocheckers.com/images/roster/josh_wesley_headshot_1718.png'
                      alt='UI Designer'
                    />
                    <div className='member-info'>
                      <h3>John Doe</h3>
                      <p>UI Designer</p>
                    </div>
                  </div>
                </div>
                <div className='member-details'>
                  <div>
                    <img
                      src='https://i.postimg.cc/50n49xjb/888-8883444-headshot-no-background-scarf.png'
                      alt='UI Designer'
                    />
                    <div className='member-info'>
                      <h3>John Doe</h3>
                      <p>UI Designer</p>
                    </div>
                  </div>
                </div>
                <div className='member-details'>
                  <div>
                    <img
                      src='https://i.postimg.cc/50n49xjb/888-8883444-headshot-no-background-scarf.png'
                      alt='UI Designer'
                    />
                    <div className='member-info'>
                      <h3>Sara Khan</h3>
                      <p>UI Designer</p>
                    </div>
                  </div>
                </div>
              </li>
              {/* /single member row ends */}
            </ul>
            {/* /end team-photos */}
          </div>
          {/* /end col-md-8 */}
          <div className='pull-left col-md-4 sm-text-center'>
            <div className='team-overview'>
              <h2>Who Are We?</h2>
              <a href='#' className='text-light'>
                Meet the Entire Team
              </a>
            
            </div>
          </div>
          {/* /end col-md-4 */}
        </div>
        {/* /end row */}

        {/* extra */}
      </div>

      <h1 className=''>Team</h1>
    </div>
  );
}

export default Teams;
