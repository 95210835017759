/** @format */

import React from "react";
import Faq from "react-faq-component";

const data = {
  //   title: "FAQ (How it works)",
  rows: [
    {
      title: "What is PovoAI?",
      content: `People can learn about blockchain technology and cryptocurrencies on a PovoAI while earning money.`,
    },
    {
      title: "Why PovoAI?",
      content: `PovoAI thinks that learning is not a successful educational model on its own, but rather that applying lessons in real-world concerns while employing rewards from the lessons is essential.`,
    },
    {
      title: "What's Our Long-Term Goal?",
      content: `The long-term objective of PovoAI is to empower millions of individuals worldwide with information, tools, and resources to explore the numerous opportunities in the cryptocurrency business by bringing blockchain technology and cryptocurrencies to them..`,
    },
    {
      title: "Who can earn on PovoAI?",
      content: `Just about anyone legally able to use the internet can earn on PovoAI..`,
    },
    {
      title: "How much can I earn on PovoAI?",
      content: `You may work as long as you want to. We create training materials, games, and other materials that might increase your earnings.`,
    },
    {
      title: "Why $PovoAI?",
      content: `$PovoAI is the PovoAI Ecosystem's utility token. It is used to reward learning, staking, governance, and partner services, among other thing.`,
    },
    {
      title: "Can I cheat with the AI tool?",
      content: `No, because each Q&A dataset that is generated from the AI is unique and does not resemble anything across any search engine.`,
    },
  ],
};

const styles = {
  bgColor: "transparent",
  titleTextColor: "#EBEEF2",
  rowTitleColor: "#EBEEF2",
  rowContentColor: "#A5ABB3",
  arrowColor: "#FFF",
  rowContentPaddingTop: "10px",
  rowContentPaddingBottom: "10px",
  rowContentPaddingLeft: "30px",
  rowContentPaddingRight: "30px",
};

const config = {
  animate: true,
  tabFocus: true,
  margin: "2px",
};

export default function Faqs() {
  return (
    <div className='container page1'>
      <div className='row res-footer'>
        <div
          className='col-md-12 text-center  blockmover-row2 m-auto'
          data-aos='fade-up'
          data-aos-duration='1500'>
          <h2>FAQ's</h2>
          <p>Frequently Asked Question</p>
        </div>

        <div data-aos='fade-right' data-aos-delay='500'>
          <Faq data={data} styles={styles} config={config} />
        </div>
      </div>
    </div>
  );
}
