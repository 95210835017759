/** @format */

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination, EffectFade } from "swiper/modules";
import Hero from "../components/elements/Hero";
import Page1 from "../components/elements/Page1";
import Page2 from "../components/elements/Page2";
import Page3 from "../components/elements/Page3";
import Page4 from "../components/elements/Page4";
import Page5 from "../components/elements/Page5";
import Page7 from "../components/elements/Page7";
import Teams from "../components/elements/Teams";

function Home() {
  return (
    <Swiper
      direction={"horizontal"}
      slidesPerView={1}
      spaceBetween={30}
      // fadeEffect={true}
      speed={1500}
      effect='slide'
      mousewheel={true}
      modules={[Mousewheel, Pagination]}
      className='mySwiper'
      breakpoints={{
        320: {
          spaceBetween: 20,
          direction: "horizontal",
        },
        // when window width is >= 480px
        480: {
          direction: "vertical",
          spaceBetween: 30,
        },
        // when window width is >= 640px
        640: {
          direction: "horizontal",

          spaceBetween: 40,
        },
      }}>
      <SwiperSlide>
        <Hero />
      </SwiperSlide>
      <SwiperSlide>
        <Page1 />
      </SwiperSlide>
   
      <SwiperSlide>
        <Page3 />
      </SwiperSlide>
      <SwiperSlide>
        <Page4 />
      </SwiperSlide>
      <SwiperSlide>
        <Page5 />
      </SwiperSlide>
      <SwiperSlide>
        <Page7 />
      </SwiperSlide>
      <SwiperSlide>
        <Teams />
      </SwiperSlide>
    </Swiper>
  );
}

export default Home;
