/** @format */

import React from "react";

function Page1() {
  return (
    <div className='container-fluid page1 px-5' id='Aim'>
      <div className='row mt-3'>
        <div className='col-md-12 '>
          <h2>Problem</h2>
        </div>
      </div>

      <div className='row mt-2'>
        <div className='col-md-3'>
          <div className='box'>
            <p>
              Developing games requires a significant investment of time,
              financial resources, and specialized expertise.{" "}
            </p>
            {/* <br />
            <br />
            <h4>
              LATENCY - <br />
              SECURITY - <br /> COST
            </h4> */}
          </div>
        </div>
        <div className='col-md-3'>
          <div className='box'>
            <p>
              Content creators lack the means to incorporate interactive
              elements into their creations.
            </p>
            {/* <br />
            <br />
            <h4>X10</h4> */}
          </div>
        </div>
        <div className='col-md-5'>
          <div className='box'>
            <p>
              The current valuation of the global gaming market stands at
              $249.55 billion, and forecasts suggest a potential ascent to
              $665.77 billion by 2030, driven by a robust compound annual growth
              rate (CAGR) of 13.1%. However, it's worth noting that this growth
              potential could even reach as high as 30%. Yet, gaming studios
              encounter formidable obstacles such as time constraints, cost
              considerations, and resource limitations.
            </p>
            {/* <br />
            <br />
            <h4>1/3</h4> */}
          </div>
        </div>
        <div className='col-md-3'>
          <div className='box'>
            <p>Content creators often find it challenging to generate passive income from their existing content.</p>
            {/* <br />
            <br />
            <h4>5%</h4> */}
          </div>
        </div>
        <div className='col-md-5'>
          <div className='box'>
            <p>Traditional gaming restricted players from withdrawing their earned rewards into fiat currency.</p>
            {/* <br />
            <br />
            <h4>5%</h4> */}
          </div>
        </div>
      </div>
      <h1>About</h1>

      {/* <div className='row fixed-bottom pb-5 footer-sc'>
        <div className='col-md-1'></div>
        <div className='col-md-6'>
          <a href='#about'>
            <button className='footer-btn '>Vision</button>
          </a>
          <a href='#Aim'>
            <button className='footer-btn active-btn'>Aim</button>
          </a>
        </div>
        <div className='col-md-4'></div>
      </div> */}
    </div>
  );
}

export default Page1;
